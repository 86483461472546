header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  transition: position 0s .5s, border .5s linear 0.5s, all .5s;
  box-sizing: border-box;
  background-position: center 29%;
  &~*{
    transition: all 0.2s;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    z-index: -1;
    background: rgba(0,0,0,.5);
    opacity: 0;
    transition: opacity 5s, width 0s 5s;
  }
  .container {
    display: flex;
    align-items: stretch;
    &:before {
      content: '';
      width: 100vw;
      min-width: 100vw;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      position: absolute;
      bottom: 0;
      height: $a1;
      background: linear-gradient(90deg, rgba(255,255,255, .1) calc(100% - #{$container-width / 100 * 18} - ((100% - #{$container-width}) / 2)), rgba(0,0,0, .1) 1%) center center;
      transition: all 0.5s;
      transition-delay: .5s;
      z-index: -1;
    }
    .logo {
      background: url(/img/logo_white.png) no-repeat 0 center / 100% auto;
      transition: all 0.5s;
      width: $a150;
    }
    .menu {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    nav {
      height: 100%;
      margin-left: auto;
      .menu {
        display: flex;
        align-items: stretch;
        height: 100%;
        margin-left: auto;
        transition: all 0.5s;
        transition-delay: .5s;
        height: 100%;
        &>li {
          margin: 0 $a5;
          transition: all 0.3s;
          border-radius: $a50;
          height: $a70;
          &.ad {
            .open {
              .left, .right {
                padding: $a20 !important;
                margin: auto;
                a {
                  transform: none;
                  margin: 0;
                  padding: 0;
                  width: 100%;
                  height: 100%;
                  position: relative;
                  &:before {
                    content: '' !important;
                    transition: all 0.5s;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: rgba(0,0,0,0.7);
                    display: block !important;
                    opacity: 0;
                  }
                  .button {
                    position: absolute;
                    right: $a30;
                    bottom: $a30;
                    opacity: 0;
                    transition: all 0.5s;
                    &:before, &:after {
                      display: none;
                    }
                  }
                  &:hover {
                    &:before {
                      opacity: 1;
                    }
                    span {
                      opacity: 1;
                    }
                  }
                  img {
                    transform: none;
                    width: 100%;
                    height: $a313;
                    filter: none;
                    margin: 0;
                  }
                }
              }
            }
          }
          img {
            width: $a15;
            filter: invert(1) brightness(.5);
            margin-left: $a10;
            margin-right: -$a10;
            transition: all 0.5s;
          }
          a {
            border-radius: $a50;
            transition: all 0.5s;
            padding: 0 $a15;
            box-sizing: border-box;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            cursor: pointer;
            letter-spacing: .04em;
            font-size: $a13;
            font-weight: bold;
            text-transform: uppercase;
          }
          .open {
            position: absolute;
            top: 0;
            width: 100vw;
            left: 50%;
            transform: translate(-50%, 0);
            display: flex;
            background: #2b2b2b;
            overflow: hidden;
            border-radius: 0 0 $a5 $a5;
            height: 0;
            transition: all .5s, padding-top 0s .5s;
            padding-top: 0;
            z-index: -1;
            opacity: 0;
            border-bottom : $a1 solid rgba(255,255,255,.1);
            .bg {
              position: absolute;
              left: 50%;
              top: 0;
              transform: translate(-50%, 0);
              width: 100%;
              height: auto;
              opacity: .3;
            }
            .container {
              display: flex;
              align-items: flex-start;
              position: relative;
              .line_holder {
                .line {
                  background: rgba(255,255,255, .1);
                  &:nth-of-type(2) {
                    display: none;
                  }
                }
              }
              .left, .right {
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-flow: row wrap;
                padding: $a50;
                .title {
                  width: 100%;
                  font-weight: 600;
                  color: #b6b6b6;
                  font-size: $a30;
                  padding-bottom: $a45;
                }
                ul {
                  display: flex;
                  flex-flow : row wrap;
                  align-items : stretch;
                  justify-content : flex-start;
                  width : 100%;
                  li {
                    font-weight: bold;
                    font-size: $a14;
                    letter-spacing: .08em;
                    text-transform: uppercase;
                    width : calc(20% - ($a15 * 4 / 5) );
                    position : relative;
                    background : gray;
                    z-index : 1;
                    border-radius : $border-radius;
                    overflow : hidden;
                    &:before {
                      content : '';
                      position: absolute;
                      left : 0;
                      top : 0;
                      width : 100%;
                      height : 100%;
                      background : rgba(0,0,0,.7);
                      z-index : -1;
                    }
                    &:nth-of-type(1) {
                      background : url("/img/mam.png") no-repeat center center / cover;
                    }
                    &:nth-of-type(2) {
                      background : url("/img/digital-archives.jpg") no-repeat center center / cover;
                    }
                    &:nth-of-type(3) {
                      background : url("/img/media-processing.jpg") no-repeat center center / cover;
                    }
                    &:nth-of-type(4) {
                      background : url("/img/ingest.png") no-repeat center center / cover;
                    }
                    &:nth-of-type(5) {
                      background : url("/img/qc.jpg") no-repeat center center / cover;
                    }
                    &:nth-of-type(6) {
                      background : url("/img/bcgg.png") no-repeat center center / cover;
                    }
                    &:nth-of-type(7) {
                      background : url("/img/ci.png") no-repeat center center / cover;
                    }
                    &:nth-of-type(8) {
                      background : url("/img/avs.jpg") no-repeat center center / cover;
                    }
                    &:nth-of-type(9) {
                      background : url("/img/ftt.jpg") no-repeat center center / cover;
                    }
                    &:nth-of-type(10) {
                      background : url("/img/products/monitoring.jpeg") no-repeat center center / cover;
                    }
                    &:not(:nth-of-type(5n)) {
                      margin-right : $a15;
                    }
                    &:not(:nth-last-of-type(-n+5)) {
                      margin-bottom: $a15;
                    }
                    a {
                      text-align: center;
                      color: #fff;
                      width : 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      box-sizing : border-box;
                      padding : $a50 $a20;
                      &:hover {
                        color: $osn-col3 !important;
                      }
                    }
                  }
                }
              }
            }
          }
          &:hover {
            &>a {
              color: $osn-col3 !important;
              &:before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .buttons {
      margin-left: $a55;
      position: relative;
      a {
        transition: all 0.5s;
      }
      a:nth-of-type(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        z-index: -1;
      }
    }
    .burger {
      display: none;
    }
  }
  .language {
    position: absolute;
    right: $a50;
    top: calc(50% - #{$a27 / 2});
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: $a26;
    overflow: hidden;
    transition: all 0.5s;
    border-radius: $a5;
    .pl, .en, .ru {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: $a5 $a22 $a5 $a5;
      z-index: 1;
      border-bottom: $a1 solid rgba(0,0,0,.1);
      order: 2;
      &.active {
        order: 1;
      }
      img {
        margin-right: $a5;
        box-shadow: 0 0 $a2 0 rgba(0,0,0,.3);
        width: $a19;
        height: $a13;
        min-height: $a13;
      }
      p {
        color: #000;
        text-transform: uppercase;
        font-weight: bold;
        font-size: $a11;
        letter-spacing: .05em;
      }
    }
    .arrow {
      position: absolute;
      right: $a5;
      top: calc($a27 / 2 - #{$a12 / 2});
      margin: auto;
      width: $a12;
      filter: contrast(0);
      z-index: -1;
    }
    &:hover, &.open {
      height: $a80;
      background: #fff;
      box-shadow: 0 $a6 $a19 0 rgba(0, 0, 0, 0.18);
      .pl, .en, .ru {
        p {
          color: #000;
        }
      }
    }
  }
  &.offset {
    width: 100%;
    //background: url("/img/index_new/bg_header1.png") no-repeat center 29% / 100% auto, #000;
    background: #fff;
    box-shadow: 0 $a2 $a4 0 rgba(0,0,0,0.1);
    border: none;
    position: fixed;
    animation: fadeInDown .5s forwards;
    .container {
      &:before {
        background: rgba(0,0,0,.1);
      }
      .logo {
        background-image: url(/img/logo.png) !important;
      }
      nav {
        ul {
          li {
            a {
              color: #000;
            }
            .open {
              ul {
                li {
                  a {
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
      .buttons {

      }
    }
  }
  &.scrollDown {
    top: -$a70;
  }
  &.active {
    &~*{
      filter: blur($a10);
    }
    &:before {
      opacity: 1;
      width: 100vw;
      height: 100vh;
      transition: opacity 0.5s, width 0s;
    }
    .container {
      .logo {
        background-image: url(/img/logo_white.png) !important;
      }
      nav {
        .menu {
          li.active {
            a {
              img {
                transform: rotate(180deg);
              }
            }
            &:before {
              content: '';
              position: absolute;
              width: 100vw;
              left: 50%;
              transform: translate(-50%,0);
              top: 100%;
              height: $a1;
              background: rgba(255,255,255,.1);
            }
            .open {
              height: $a390;
              opacity: 1;
              padding-top: $a70;
              transition: all .5s, padding-top 0s;
            }
          }
          li {
            a {
              color: #fff;
            }
          }
        }
      }
      .button {
        border-color: #fff;
        color: #fff;
        img {
          filter: invert(1);
        }
      }
    }
    .language {
      a {
        p {
          color: #fff;
        }
      }
    }
  }
}
.mobile-menu {
  display: none;
}

/*@media screen and (max-width: $a1400) {
  header {
    .language {
      right: $a10;
    }
    .container {
      box-sizing: border-box;
      padding-right: $a15;
      .buttons {
        margin-left: $a40;
      }
    }
  }
}

@media screen and (max-width: $a1350) {
  header {
    &>.container {
      box-sizing: border-box;
      padding-right: $a50;
      .buttons {
        margin-left: $a30;
        .button {
          img {
            display: none;
          }
        }
      }
    }
    &.offset {
      padding-right: $a30;
    }
  }
  main {
    .header {
      .line_holder {
        .line:nth-of-type(3) {
          opacity: 0;
        }
      }
    }
    .advantages {
      .container {
        .item {
          .num {
            display: none;
          }
        }
      }
    }
  }
}*/

@media screen and (max-width: $mobi_start) {
  header {
    .container {
      padding: $m10 0 $m10 0 !important;
      &:before {
        background: rgba(255,255,255,.1);
      }
      .menu {
        display: none;
      }
      .logo {
        height: $m30;
        width: $m120;
      }
      .burger {
        display: block;
        width: $m20;
        height: $m15;
        background: linear-gradient(0deg, #fff, #fff) no-repeat 0 0 / 100% $m2, linear-gradient(0deg, #fff, #fff) no-repeat 0 center / 100% $m2, linear-gradient(0deg, #fff, #fff) no-repeat 0 100% / 100% $m2;
        margin: auto 0 auto auto;
      }
    }
    .language {
      right: 2.5%;
      height: $m17;
      border-radius: $m3;
      display: none;
      .ru, .pl, .en {
        height: $m18;
        min-height: $m18;
        padding: 0 0 0 $m3;
        width: $m50;
        p {
          font-size: $m7 !important;
          letter-spacing: inherit;
          line-height: inherit !important;
          color: #fff;
          padding-right: 0 !important;
        }
      }
      .arrow {
        right: $m5;
        top: $m5;
        width: $m9;
        filter: invert(1);
        transition: all 0.5s;
      }
      &.open {
        height: $m55;
        box-shadow: 0 $m6 $m19 0 rgba(0,0,0,0.18);
        background: #fff;
        .ru, .pl, .en {
          p {
            color: #000;
          }
        }
        .arrow {
          filter: none;
          transform: rotate(180deg);
        }
      }
    }
    &.offset {
      padding-right: 0;
      .container {
        .burger {
          background: linear-gradient(0deg, #000, #000) no-repeat 0 0 / 100% $m2, linear-gradient(0deg, #000, #000) no-repeat 0 center / 100% $m2, linear-gradient(0deg, #000, #000) no-repeat 0 100% / 100% $m2;
        }
      }
      .language {
        .pl, .ru, .en {
          p {
            color: #000;
          }
        }
      }
    }
    &.scrollDown {
      top: -$m50;
    }
  }
  .mobile-menu {
    display: flex;
    position: fixed;
    left: 100%;
    top: 0;
    height: 100%;
    max-height: 100%;
    width: 100vw;
    z-index: 10000000;
    box-sizing: border-box;
    background: url(/img/index_new/bg_header1.png) center center / cover, #232323;
    transition: all 0.5s;
    overflow: hidden;
    &.open {
      left: 0;
    }
    .close {
      position: absolute;
      right: $m15;
      top: $m15;
      width: $m20;
      height: $m20;
      background: linear-gradient(0deg, #fff, #fff) no-repeat center center / 100% $m2, linear-gradient(0deg, #fff, #fff) no-repeat center center / $m2 100%;
      transform: rotate(45deg);
    }
    .logo {
      top: $m20;
      left: $m15;
      width : $m100;
      position: absolute;
      transition: all 0.5s;
      img {
        width : 100%;
      }
    }
    .step1 {
      min-width: 100%;
      display: flex;
      flex-direction: column;
      padding: $m60 $m15 0 $m15;
      box-sizing: border-box;
      transition: all 0.5s;
      nav {
        .menu {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          li {
            margin-bottom: $m10;
            a, .a {
              font-size: $m20;
              font-weight: bold;
              letter-spacing: .03em;
              display: flex;
              align-items: center;
              img {
                width: $m15;
                transform: rotate(-90deg);
                filter: invert(1);
                margin-left: $m10;
                margin-top: $m5;
              }
            }
            .a {
              color: #adadad;
            }
            .open {
              display: none;
            }
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: $m20;
        margin-top: auto;
        .button {
          width: 48%;
          img {
            height: 50%;
            filter: invert(1);
            margin-right: $m10;
          }
          &:hover {
            img {
              filter: none;
            }
          }
        }
      }
    }
    .step2 {
      min-width: 100%;
      padding: 0 $m15;
      height: 100%;
      overflow: auto;
      box-sizing: border-box;
      .back {
        display: flex;
        color: #fff;
        font-size: $m14;
        letter-spacing: .03em;
        padding: 0;
        margin-top: $m17;
        margin-bottom: $m25;
        img {
          transform: rotate(90deg);
          filter: invert(1);
          width: $m15;
          margin-right: $m10;
        }
      }
      .title {
        font-size: $m20 !important;
        letter-spacing: .03em;
        margin-bottom: $m20;
        font-weight: bold;
      }
      ul {
        margin-bottom: $m20;
        li {
          margin-bottom: $m10;
          a {
            font-weight: 100;
            letter-spacing: .03em;
            font-size: $m14;
          }
        }
      }
    }
    &.next {
      .logo {
        opacity: 0;
        z-index: -1;
      }
      .step1 {
        margin-left: -100%;
      }
    }
  }
}
