.offer {
    .container {
        display: flex;
        align-items: flex-end;
        padding-bottom: $a100;
        .line_holder {
            .line {
                background: #e8e8e8;
            }
            .line:not(:first-of-type) {
                opacity: 0;
            }
        }
        .left {
            width: 50%;
            box-sizing: border-box;
            padding-right: $a55;
            padding-bottom: $a170;
            h2 {
                padding-bottom: $a60;
                text-align: left;
            }
            p {
                padding-bottom: $a40;
                line-height: $a26;
                color: #000;
            }
        }
        .bottom {
            position: absolute;
            left: 0;
            bottom: $a120;
            hr {
                border-bottom-color: rgba(0,0,0,1);
                margin-bottom: $a30;
            }
            .accent {
                font-weight: bold;
                font-size: $a18;
                color: #000;
                padding-bottom: $a40;
            }
            .link {
                color: #3b6eaa;
                font-size: $a15;
                letter-spacing: .04em;
            }
        }
        .right {
            background: #f1f8fc;
            width: 50%;
            box-sizing: border-box;
            border-radius: $a5;
            margin-top: -$a72;
            transform-style: preserve-3d;
            border: $a1 solid #eee;
            position: relative;
            overflow: hidden;
            transition: all 0.5s;
            &.active {
                box-shadow: -$a1 $a29 $a33 0px rgba(0, 0, 0, 0.14);
                #license {
                    right: 0;
                }
            }
            .title {
                width: 100%;
                height: $a72;
                background: #3b6eaa;
                display: flex;
                align-items: center;
                padding-left: $a80;
                box-sizing: border-box;
                font-size: $a16;
                color: #fff;
                text-transform: uppercase;
                border-radius: $a5 $a5 0 0 ;
                box-shadow: 0 $a17 $a19 0 rgba(23, 28, 82, 0.13);
            }
            .body {
                padding: $a65 $a80 $a45 $a80;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                ul {
                    li {
                        margin-bottom: $a25;
                        display: flex;
                        align-items: center;
                        color: #000;
                        &:before {
                            content: '';
                            min-width: $a5;
                            height: $a5;
                            border-radius: 50%;
                            border: $a1 solid #000;
                            margin-right: $a20;
                            display: flex;
                        }
                    }
                }
                hr {
                    margin: $a20 0;
                    border-color: rgba(0,0,0,.1);
                    width: 100%;
                }
                p {
                    font-weight: bold;
                    font-size: $a15;
                    color: #141414;
                    margin: 0 auto;
                    text-align: center;
                }
                .price {
                    font-weight: normal;
                    color: #141414;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                    margin: $a10 0;
                    width: 100%;
                    span {
                        color: #fa4e37;
                        font-family: 'ElektraTextPro-Bold';
                        margin-right: $a10;
                        span {
                            font-size: $a40;
                            margin-right: 0;
                            margin-left: $a10;
                        }
                    }
                }
                .buttons {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    &>.button:not(.small) {
                        margin: $a30 0 0 0;
                        width: 48%;
                        box-sizing: border-box;
                        padding: 0;
                        text-align: center;
                    }
                }
                &>.button:not(.small) {
                    margin: $a30 auto 0 auto;
                }
            }
        }
        #license {
            position: absolute;
            top: 0;
            right: 100%;
            width: 100%;
            height: 100%;
            background: #fff;
            z-index: 1;
            border-radius: $a5;
            transition: all 0.5s;
            .title {
                background: $osn-col1;
                position: relative;
                img {
                    position: absolute;
                    left: 2%;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 40%;
                    filter: invert(1);
                    cursor: pointer;
                }
            }
            .body {
                padding-top: $a30;
                ul {
                        li {
                                &:last-of-type {
                                        &:before {
                                                opacity: 0;
                                        }
                                }
                        }
                }
                .small {
                    margin: 0 auto;
                }
                p {
                    font-size: $a20;
                }
                .price {
                    color: $osn-col1;
                    font-size: $a45;
                    margin: 0;
                    span {
                        font-size: $a75;
                        color: $osn-col1;
                    }
                }
                hr {
                    border-bottom-color: rgba(0,0,0,.1);
                    width: 100%;
                }
            }
        }
    }
}


@media screen and (max-width: $mobi_start) {
    .offer {
        .container {
            flex-direction: column;
            padding-bottom: 0 !important;
            .left {
                width: 100%;
                padding-right: 0;
                padding-bottom: 0;
                .link {
                    font-size: $m10;
                }
            }
            .bottom {
                position: static;
                padding-bottom: $m30;
                hr {
                    margin-bottom: $m20;
                    margin-top: $m20;
                }
                p {
                    padding-bottom: $m30;
                }
            }
            .right {
                margin-top: 0;
                width: 100%;
                .title {
                    font-size: $m10 !important;
                    height: $m30;
                    padding-bottom: 0 !important;
                    border-radius: $m3 $m3 0 0;
                    padding-left: $m20;
                }
                .body {
                    padding: $m20;
                    ul {
                        li {
                            margin-bottom: $m10;
                            &:before {
                                min-width: $m3;
                                height: $m3;
                                margin-right: $m10;
                            }
                            font-size: $m10;
                        }
                    }
                    hr {
                        margin: $m10 0;
                    }
                    p {
                        padding-right: 0 !important;
                    }
                    .price {
                        font-size: $m10;
                        margin: $m15 0;
                        span {
                            margin-right: $m3;
                            span {
                                font-size: $m25;
                                margin-left: $m3;
                            }
                        }
                    }
                }
            }
        }
    }
}
