$osn-col2: #ff5800;
$osn-col1: #ffc7ae;
$osn-col3: #ff8a57;
$shaded: #f6f8fa;
$gray: #f6f6f6;
$grayText: #c2c2c2;
$mobi_start: 1023px;

@import "components/pxTOvw";
@import "components/animate";
$container-width: $a1180;
$border-radius: $a5;
$dark: #2a2a2a;
@import "header";
@import "footer";


::-webkit-scrollbar {
  width: $a10;
}
::-webkit-scrollbar-track {
  background: none;
  width : 0;
}
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius : $a10;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: gray;
  border-radius : $a10;
}
::-webkit-input-placeholder {color: #32313c;}
::-moz-placeholder          {color: #32313c;}
::-ms-input-placeholder      {color: #32313c;}
:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
}

::selection {
  background: $osn-col3;
  color: #fff;
}
::-moz-selection {
  background: $osn-col3;
  color: #fff;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: transparent;
}
input:focus::-moz-placeholder, textarea:focus::-moz-placeholder { /* Firefox 19+ */
  color: transparent;
}
input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder { /* IE 10+ */
  color: transparent;
}
input:focus:-moz-placeholder, textarea:focus:-moz-placeholder { /* Firefox 18- */
  color: transparent;
}

input, textarea {
  font-family: 'Montserrat', serif;
}

* {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
  backface-visibility: visible;
}

@keyframes arrow {
  50% {
    transform: translateX(10%);
  }
}
@keyframes arrow_reverse {
  50% {
    transform: translateX(-10%);
  }
}

html {
  position: relative;
  body {
    position: relative;
    margin: 0;
    padding: 0;
    background: #fff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: $a14;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    * {
      transform-style: flat;
    }
    main {
      position: relative;
      z-index: 3;
      overflow: hidden;
      background-color: transparent;
      .header {
        height: calc(100vh + $a1);
      }
    }
    picture {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }

    p {
      margin: 0;
      padding: 0;
      white-space: pre-line;
      line-height: 1.5;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    a {
      text-decoration: none;
      color: #fff;
      cursor: pointer;
      &:focus, &:hover {
        text-decoration: none;
      }
    }

    button {
      border: none;
      cursor: pointer;
      background: none;
    }

    svg {
      overflow: visible;
    }

    h1,h2,h3,h4,h5,h6 {
      font-family: 'ElektraTextPro';
      font-weight: 100;
      white-space: pre-line;
      margin: 0;
      padding: 0;
      color: #141414;
    }

    h1 {
      font-size: $a70;
    }

    h2 {
      font-size: $a60;
      color: #141414;
      text-align: center;
      span {
        font-family: 'ElektraTextPro-Bold';
      }
    }

    h3 {
      font-size: $a35;
      text-align: center;
    }

    h4 {
      font-size: $a18;
      font-family: 'Open Sans';
      font-weight: bold;
    }
  }
}

.wow {
  visibility: hidden;
}



.list {
  li {
    margin-bottom: $a25;
    display: flex;
    align-items: center;
    color: #000;
    &:before {
      content: '';
      min-width: $a5;
      height: $a5;
      border-radius: 50%;
      border: $a1 solid #000;
      margin-right: $a20;
      display: flex;
    }
    p {
      font-size: $a16;
      font-weight: bold;
      span {
        color: $osn-col2;
        font-weight: 900;
      }
    }
  }
}

.lastAllowString {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.container {
  width: $container-width;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .line_holder {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    box-sizing: border-box;
    display: none !important;
    .line {
      height: 100%;
      width: $a1;
      background: rgba(0,0,0, .1);
    }
  }
}

.svg_gradient {
  position: fixed;
  right: 100%;
  top: 0;
  width: 0;
  height: 0;
  #grad {
    .stop1 {
      stop-color: $osn-col2;
    }
    .stop2 {
      stop-color: $osn-col2;
    }
  }
}

.button {
  height: $a65;
  width: fit-content;
  padding: 0 $a60;
  border: $a2 solid #fff;
  box-sizing: border-box;
  border-radius: $a3;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: $a18;
  letter-spacing: .08em;
  overflow: hidden;
  position: relative;
  transition: all 0.5s;
  z-index: 1;
  cursor: pointer;
  white-space: nowrap;
  $sizeBA: 225%;
  $transfrom: translate(-50%, 0) rotate(-25deg);
  $topBottom: 295%;
  &:before {
    content: '';
    position: absolute;
    width: $sizeBA;
    height: $sizeBA;
    left: 50%;
    bottom: $topBottom;
    transform: $transfrom;
    background: #fff;
    transition: all 0.5s ease-in-out;
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    width: $sizeBA;
    height: $sizeBA;
    left: 50%;
    top: $topBottom;
    transform: $transfrom;
    background: #fff;
    transition: all 0.5s ease-in-out;
    z-index: -1;
  }
  &:hover {
    color: #000;
    &:before {
      bottom: 50%;
    }
    &:after {
      top: 50%;
    }
  }
  &.circle {
    width: $a52;
    height: $a52;
    border-radius: 50%;
    padding: 0;
    margin-left: auto;
    margin-right: $a50;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $a1 solid rgba(0,0,0,.1);
    img {
      width: $a10;
      transition: all 0.5s;
    }
    &:after {
      display: none;
    }
    &:before {
      //background: linear-gradient( 45deg, $osn-col1 0%, $osn-col2 100%);
      background : $osn-col2;
      opacity: 0;
      transition: all 0.5s;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100%;
    }
    &:hover {
      border-color: transparent;
      &:before {
        opacity: 1;
      }
    }
  }
  &.dark {
    border-color: #2b2b2b;
    color: #2b2b2b;
    &:before, &:after {
      background: #2b2b2b;
    }
    &:hover {
      color: #fff;
      img {
        filter: invert(1);
      }
    }
  }
  &.small {
    height: $a40;
    padding: 0 $a20;
    font-size: $a14;
    img {
      margin-right: $a10;
      height: 50%;
      transition: all 0.5s;
    }
  }
  &.lg {
    border: none;
    box-shadow: 0 $a17 $a19 0 rgba(23, 28, 82, 0.13);
    color: #fff;
    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: inherit;
      z-index: -1;
      rect {
        width: 100%;
        height: 100%;
      }
      .svg_border {
        stroke: url(#grad);
        stroke-width: $a5;
      }
      .svg_bg {
        fill: url(#grad);
        transition: all 0.5s;
      }
    }
    &:after, &:before {
      display: none;
    }
    &:hover {
      color: #fff;
      svg {
        .svg_bg {
          width: calc(100% - #{$a14}) !important;
          height: calc(100% - #{$a14}) !important;
          transform: translate($a7, $a7) !important;
        }
      }
    }
    &.reverse {
      color: $osn-col1;
      svg {
        .svg_bg {
          width: 100%;
          height: 100%;
          transform: translate(-100%, 0);
        }
      }
      &:hover {
        color: #fff;
        svg {
          .svg_bg {
            transform: none !important;
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
}

.svg_gradient {
  position: absolute;
  left: 100%;
  opacity: 0;
  z-index: -100;
  .stop1 {
    stop-color: $osn-col2;
  }
  .stop2 {
    stop-color: $osn-col2;
  }
}

.link {
  color: #3b6eaa;
  font-size: $a15;
  letter-spacing: .04em;
  &.green {
    color: $osn-col1;
  }
  &.arrow {
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: underline;
    &:after {
      content: '→';
      display: inline-block;
      text-decoration: none;
      margin-left: $a10;
      font-family: "ElektraTextPro";
    }
    &:hover {
      &:after {
        animation: arrow 1s forwards infinite;
      }
    }
  }
}

@media screen and (max-width: $mobi_start) {
  ::-webkit-scrollbar {
    width: $a2;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255,255,255,.1);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255,255,255,.1);
  }
  html {
    body {
      font-size: $m10;
      main {
        .header {
          height: auto !important;
          padding-top: $m50;
        }
      }
    }
  }
  .container {
    width: 95% !important;
    box-sizing: border-box;
    padding-top: $m20 !important;
    padding-bottom: $m20 !important;
    .line_holder {
      .line:nth-of-type(2n-1) {
        opacity: 1 !important;
      }
    }
  }

  h1 {
    font-size: $m30 !important;
    line-height: $m35 !important;
    margin-bottom: $m15 !important;
  }

  h2 {
    font-size: $m22 !important;
    text-align: left !important;
    padding: 0 $m5 $m20 0 !important;
  }

  h3 {
    font-size: $m20 !important;
    padding-bottom: $m18 !important;
  }

  h4 {
    font-size: $m18 !important;
    padding-bottom: $m15 !important;
  }

  p {
    font-size: $m12 !important;
    white-space: normal !important;
    text-align: left !important;
    line-height: $m20 !important;
    hyphens: auto;
    padding-right: $m30 !important;
  }

  .button {
    height: $m40;
    padding: 0 $m20 !important;
    font-size: $m13;
    border-radius: $m3;
    &.small {
      height: $m25;
      font-size: $m8 !important;
      padding: 0 $m10 !important;
    }
    &.dark {
      &:hover {
        transition: background 0.5s .5s;
        background: #2b2b2b;
      }
    }
  }
}
