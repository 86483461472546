header {
    .container {
        &:before {
            background: rgba(255,255,255,.1);
        }
        nav {
            ul {
                li {
                    a {
                        color: #fff;
                    }
                }
            }
        }
        .buttons {
            .button {
                border-color: #fff;
                color: #fff;
                img {
                    filter: invert(1);
                }
                &:before, &:after {
                    background: #fff;
                }
                &:hover {
                    color: #000;
                    img {
                        filter: none;
                    }
                }
            }
        }
    }
    .language {
        .pl {
            p {
                color: #fff;
            }
        }
    }
    &.offset {
        .container {
            .buttons {
                .button {
                    color : #2b2b2b;
                    border-color : #2b2b2b;
                    &:before, &:after {
                        background : #2b2b2b;
                    }
                    &:hover {
                        color : #fff;
                    }
                }
            }
        }
    }
    &.active {
        .container {
            .buttons {
                .button {
                    border-color: #fff;
                    color: #fff;
                    img {
                        filter: invert(1);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobi_start) {

}
