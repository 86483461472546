.ticker {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
    display: flex;
    z-index: 10;
    picture {
        height: $a250;
        width: auto;
        margin-right: $a25;
        img {
            width: auto;
            height: 100%;
        }
    }
}

@media screen and (max-width: $mobi_start) {
    .ticker {
        picture {
            height: $m140;
            margin-right: $m10;
        }
    }
}
