@import "../all_page";
@import "../components/header-dark";
@import "../components/slick";
@import "../components/slick-theme";
@import "../components/offer";
@import "../components/partner";
@import "../components/ticker";

main {
  color: #5d5c65;
  z-index: 3;
  position: relative;
  .header {
    background: url("/img/1.jpg") no-repeat 0 center / cover;
    overflow: hidden;
    position: relative;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #ffffff;
    border-bottom: $a1 solid rgba(0,0,0, .1);
    z-index : 1;
    &:before {
      content : '';
      position: absolute;
      top : 0;
      left : 0;
      width : 100%;
      height : 100%;
      z-index : -1;
      background : rgba(43,43,43,.9);
    }
    .container {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      z-index: 1;
      position : relative;
      /*&:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 18%;
        height: 100%;
        background: #fff;
      }
      &:after {
        content: '';
        left: 100%;
        top: 0;
        height: 100%;
        width: calc((100vw - 100%) / 2);
        background: #fff;
        position: absolute;
        z-index: -1;
      }*/
      .line_holder {
        z-index: 1;
        .line:not(:last-of-type) {
          opacity: 0;
        }
      }
      .left {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        width : 100%;
        h1 {
          font-size: $a75;
          margin-bottom: $a30;
          color: #fff;
          text-align: center;
        }
        p {
          font-size: $a20;
          font-family: 'Open Sans';
          font-weight: 600;
          line-height: $a34;
          text-align: center;
          &.nowrap {
            white-space: nowrap;
            margin-bottom : $a10;
          }
        }
        .button {
          margin-top: $a55;
        }
      }
      .right {
        position: relative;
        margin-right: -$a150;
        width: $a695;
        height: $a651;
        display: flex;
        align-items: center;
        z-index: 1;
        display: none;
        .bg_d {
          width: 100%;
        }
        .bg {
          clip-path: url(#display);
          height: $a645;
          .img {
            width: $a815;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -55%);
          }
        }
        svg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          fill: none;
          stroke: #464646;
          stroke-width: $a6;
        }
        .displays {
          position: absolute;
          left: 0;
          top: $a50;
          width: 100%;
          height: 100%;
          z-index: 10;
          display: flex;
          align-items: center;
          .displayBg {
            width: $a700;
          }
        }
      }
      .logo {
        position: absolute;
        bottom : 0;
        left : 0;
        width : 100%;
        display: flex;
        justify-content : space-between;
        div {
          width : 100% / 5;
          img {
            height : $a50;
          }
        }
      }
    }
  }
  .what {
    border-bottom : $a1 solid rgba(0,0,0,.1);
    position : relative;
    &:before {
      width: $a1700;
      height: $a305;
      content: '';
      background-color: rgb(246, 246, 246);
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .container {
      padding: $a110 0 $a70 0;
      display: flex;
      flex-flow : row wrap;
      .line_holder {
        .line:nth-of-type(3) {
          opacity: 0;
        }
      }
      h2 {
        width : 100%;
        margin-bottom : $a50;
      }
      .item {
        width : 100%;
        box-sizing : border-box;
        display: flex;
        flex-flow : row wrap;
        &:nth-of-type(2) {
          margin-bottom : $a35;
        }
        img {
          width : $a60;
          margin-right : $a20;
        }
        h3 {
          margin-bottom : $a35;
        }
        .text {
          width : 50%;
          ul {
            width : 100%;
            li {
            }
          }
        }
        h3 {
          text-align : left;
          &:nth-of-type(2) {
            margin-top: auto;
          }
        }
        ul {
          display: flex;
          flex-direction: column;
          justify-content : space-between;
          width : 50%;
          overflow : hidden;
          li {
            display: flex;
            align-items: center;
            position : relative;
            width : 100%;
            padding-right : $a80;
            box-sizing : border-box;
            &:not(:last-of-type) {
              margin-bottom : $a25;
            }
            &:not(.title) {
              /*&:before {
                content : '';
                position: absolute;
                top : 0;
                bottom : 0;
                margin: auto;
                right : 102%;
                width : $a12;
                height : $a12;
                border-radius : 50%;
                background: linear-gradient(90deg, $osn-col1, $osn-col2);
              }*/
            }
          }
        }
      }
    }
  }
  .advantages {
    border-bottom: $a1 solid rgba(0,0,0, .1);
    .container {
      padding: $a70 0;
      .line_holder {
        .line:not(:last-of-type) {
          opacity: 0;
        }
      }
      h2 {
        padding-bottom: $a30;
      }
      .item {
        display: flex;
        align-items: center;
        padding: $a30 0;
        position: relative;
        color: #141414;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: calc((-100vw + #{$a1180}) / 2);
          height: 100%;
          width: 0;
          background: #f6f6f6;
          transition: all 0.5s;
          z-index: -1;
        }
        .num {
          padding-right: $a20;
          margin-top: auto;
          font-weight: 300;
          position: absolute;
          right: 100%;
          bottom: $a20;
          font-size: $a50;
          color: #141414;
          opacity: .2;
          transition: all 0.5s;
          display: none;
        }
        .img {
          width: $a278;
          height: $a150;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $a5;
          box-shadow: 0px $a13 $a19 0px rgba(0, 0, 0, 0.2);
          margin-right: $a65;
          img {
            width: 100%;
            height: auto;
          }
        }
        .text {
          width: 60%;
          h4 {
            padding-bottom: $a28;
          }
          p {
            line-height: $a26;
          }
        }
        .button {
          transition: all 0.5s;
          img {
            transition: all 0.5s;
          }
          p {
            display: none;
          }
        }
        &:hover {
          &:before {
            width: calc(100vw - (100vw - #{$a1180}) / 2);
          }
          .num {
            opacity: 1;
          }
          .button {
            &:before {
              opacity: 1;
            }
            img {
              filter: invert(1);
            }
          }
        }
      }
    }
  }
  .digital {
    background: url(/img/index_new/mountains.png) no-repeat 0 0 / cover;
    margin-top: -$a100;
    position: relative;
    .container {
      padding-top: $a175;
      padding-bottom: $a175;
      display: flex;
      flex-flow: row wrap;
      color: #fff;
      .line_holder {
        .line {
          background: #e8e8e8;
          opacity: .1;
        }
        .line:nth-of-type(1) {
          opacity: 0;
        }
      }
      h3 {
        padding-bottom: $a76;
        width: 100%;
        color: #fff;
        font-weight: bold;
      }
      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: $a60;
        width: 50%;
        &:nth-child(2n) {
          margin-left: calc(#{-$a62 - $a22});
        }
        img {
          width: $a62;
          margin-right: $a22;
        }
        h4 {
          color: #fff;
          padding-bottom: $a22;
        }
        p {
          color: #c2c2c2;
          line-height: $a26;
          font-size: $a14;
        }
      }
    }
  }
  .step {
    z-index: -1;
    border-bottom: $a1 solid rgba(0,0,0, .1);
    .container {
      padding-top: $a220;
      padding-bottom: $a160;
      .line_holder {
        .line:not(:last-of-type) {
          opacity: 0;
        }
      }
      h2 {
        padding-bottom: $a70;
        width: 100%;
      }
      .circle-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 0 $a55;
        margin-bottom: $a150;
        width: 100%;
        box-sizing: border-box;
        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: $a1;
          margin: auto;
          background: rgba(0,0,0,.1);
          content: '';
        }
        $border_color: #c8c8c8;
        .step {
          display: flex;
          justify-content: center;
          align-items: center;
          width: $a132;
          height: $a132;
          border: $a1 solid #ececec;
          border-radius: 50%;
          position: relative;
          z-index: 1;
          background: #fff;
          transition: all 0.5s;
          .button {
            position: absolute;
            top: calc(100% + #{$a32});
            font-size: $a13;
          }
          .border2 {
            width: 93%;
            height: 93%;
            border-width: $a10;
            border-style: solid;
            border-left-color: $border_color;
            border-top-color: $border_color;
            border-bottom-color: transparent;
            border-right-color: transparent;
            border-radius: 50%;
            position: absolute;
            transform: rotate(-20deg);
            transition: all 1s ease-in-out;
          }
          .circle {
            font-weight: 400;
            border-radius: 50%;
            background-color: rgb(255, 255, 255);
            box-shadow: 0 $a9 $a27 0 rgba(0, 0, 0, 0.13);
            width: 60%;
            height: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.5s;
            font-size: $a46;
            position: relative;
          }
          &.active {
            .circle {
              box-shadow: 0 0 0 0, inset 0 $a9 $a27 0 rgba(0, 0, 0, 0.13);
              width: 100%;
              height: 100%;
            }
            .border2 {
              transform: rotate(360deg);
            }
            .button {
              box-shadow: 0 $a17 $a19 0 rgba(9, 9, 9, 0.14);
              color: #fff;
              img {
                filter: invert(1);
              }
              &:before {
                bottom: 50%;
              }
              &:after {
                top: 50%;
              }
            }
          }
        }
        .two {
          .border2 {
            border-top-color: $border_color;
            border-left-color: $border_color;
            border-bottom-color: transparent;
            border-right-color: $border_color;
          }
        }
        .three {
          .circle {
            background: #3d3d3d;
            color: #fff;
            font-weight: bold;
          }
          .border2 {
            border-top-color: $border_color;
            border-left-color: $border_color;
            border-bottom-color: $border_color;
            border-right-color: $border_color;
            transition: all 0.5s;
          }
          .text {
            color: #fff;
          }
        }
        .circle-small {
          border-width: $a1;
          border-color: rgba(0,0,0,.1);
          border-style: solid;
          border-radius: 50%;
          background-color: rgb(255, 255, 255);
          width: $a24;
          height: $a24;
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          margin: auto;
          &:first-of-type {
            transform: translate(-50%, 0);
            left: 0;
          }
          &:last-of-type {
            transform: translate(50%, 0);
            right: 0;
          }
        }
      }
      .item-holder {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        .circle {
          width: $a67;
          height: $a67;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: $a1 solid rgba(0,0,0,.1);
          margin: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 10%;
          transform: translate(-100%, 0);
          &:before {
            content: '';
            width: 60%;
            height: $a3;
            background: #000;
          }
          .circle2 {
            position: absolute;
            border-radius: 50%;
            border: $a1 solid rgba(0,0,0,.1);
            left: 0;
            @for $i from 1 through 3 {
              &:nth-of-type(#{$i}) {
                width: calc(100% + 68% * #{$i});
                height: calc(100% + 68% * #{$i});
                transform: translate(#{-8% - 2 * $i}, 5%);
              }
            }
          }
        }
        .item {
          display: flex;
          align-items: center;
          width: 85%;
          padding-left: $a70;
          animation: fadeOutUp 1s forwards;
          position: absolute;
          height: $a100;
          &.active {
            position: relative;
            animation: fadeInUp 1s forwards;
          }
          .name {
            display: flex;
            align-items: center;
            margin-right: $a76;
            p {
              font-size: $a22;
              font-weight: bold;
              color: #141414;
            }
          }
        }
      }
    }
  }
  .ticker-holder {
    position: relative;
    .partner {
      padding-bottom: $a120;
      margin-bottom: $a80;
      .logo {
        top: 0;
      }
    }
  }
  .article {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: $a120;
    padding-bottom: $a120;
    h2 {
      padding-bottom: $a40;
    }
    .h2_under {
      padding-bottom: $a90;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: .02em;
      line-height: $a30;
      text-align: center;
    }
    .container {
      display: flex;
      justify-content: space-between;
      transform-style: preserve-3d;
      align-items: stretch;
      a {
        width: 31.5%;
        .item {
          width: 100%;
          &:after {
            content: 'czytać →';
            height: $a60;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            box-sizing: border-box;
            padding-right: $a25;
            position: relative;
            border-top: $a1 solid rgba(255,255,255,.1);
            font-weight: bold;
            text-decoration: underline;
            text-transform: uppercase;
          }
          .name {
            width: 100%;
          }
        }
      }
      .item {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        transition: all 0.5s;
        height: $a512;
        width: 31.5%;
        box-sizing: border-box;
        color: #fff;
        overflow: hidden;
        border-radius: $a5;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          background: linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0) 100%);
          z-index: -1;
        }
        .img {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 0;
          left: 0;
          position: absolute;
          z-index: -2;
          img {
            width: auto;
            height: 100%;
          }
        }
        .name {
          width: 75%;
          height: $a50;
          color: #fff;
          padding: 0 $a25;
          box-sizing: border-box;
        }
        .text {
          white-space: normal;
          line-height: $a24;
          font-size: $a13;
          padding: $a20 $a25 $a50 $a25;
          box-sizing: border-box;
        }
        .footer {
          height: $a60;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 100%;
            height: $a1;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(255,255,255,.1);
            width: calc(100% + #{$a50});
          }
          .link {
            font-size: $a13;
            letter-spacing: .06em;
            color: #fff;
          }
        }
      }
    }
    .button {
      margin-top: $a60;
      padding: 0 $a37;
    }
  }
}

@media screen and (max-width: $mobi_start) {
  main {
    .header {
      background-size: cover;
      border: none;
      .container {
        padding-top: $m35;
        &:before, &:after {
          display: none;
        }
        .line_holder {
          .line:nth-of-type(2n-1) {
            opacity: 1;
            background: rgba(255,255,255,.1);
          }
        }
        .right {
          display: none;
        }
        .left {
          width: 100%;
          p {

          }
          .button {
            margin-top: $m30;
          }
        }
      }
    }
    .advantages {
      .container {
        .item {
          flex-direction: column;
          &:before {
              display: none;
          }
          .img {
            width: 80%;
            height: $m140;
            margin-right: auto;
          }
          .text {
            width: 100%;
            padding-top: $m30;
            h4 {
              overflow : hidden;
              text-overflow: ellipsis;
            }
          }
          .button {
            width: auto;
            height: $m40;
            border-radius: $m3;
            margin-right: auto;
            margin-left: 0;
            margin-top: $m20;
            border-color: #000;
            img {
              display: none;
            }
            p {
              display: block;
              padding-right: 0 !important;
            }
          }
          &:hover {
              .button {
                  color: #fff;
                border-color: #fff;
              }
          }
        }
      }
    }
    .what {
      &:before {
        width : 100%;
        height : $m395;
      }
      .container {
        padding-bottom : 0 !important;
        h3 {
          margin-bottom : 0 !important;
        }
        .item {
          .text {
            width : 100%;
            margin-bottom : $m20;
          }
          ul {
            width : 100%;
            li {
              width : 100%;
              padding-right : 0;
              margin-bottom : $m10 !important;
            }
          }
        }
      }
    }
    .digital {
      margin-top: -$m50;
      .container {
        padding-top: $m60 !important;
        padding-bottom: $m60 !important;
        h3 {
          text-align: left !important;
          margin: $m10 0;
          font-size: $m22 !important;
        }
        .line_holder {
          .line {
            background: rgba(255,255,255,.1);
          }
        }
        .item {
          width: 100%;
          flex-direction: column;
          margin-left: 0 !important;
          margin-bottom: $m40;
          position: relative;
          img {
            margin: 0;
            width: $m50;
            position: absolute;
            right: 0;
            top: 0;
          }
          .text {
            h4 {
              width: 70%;
            }
          }
        }
      }
    }
    .step {
      .container {
        padding-top: $m100 !important;
        .circle-holder {
          padding: 0 $m20;
          margin-bottom: $m30;
          .circle-small {
            width: $m10;
            height: $m10;
          }
          .step {
            width: $m55;
            height: $m55;
            .border2 {
              border-width: $m5;
            }
            .circle {
              font-size: $m20;
            }
            .button {
              display: none;
            }
          }
        }
        .item-holder {
          .circle {
            top: 0;
            bottom: auto;
            width: $m30;
            height: $m30;
            transform: none;
            left: 0;
          }
          .item {
            flex-direction: column;
            height: $m150;
            padding-top: $m3;
            width: 100%;
            padding-left: 0;
            .name {
              padding-bottom: $m10;
              margin-right: 0;
            }
          }
        }
      }
    }
    .article {
      padding: $m30 0;
      .h2_under {
        padding: 0 $m30;
      }
      .container {
        flex-direction: column;
        a {
          width: 100%;
          .item {
            &:after {
              height: $m30;
              padding-right: 0;
              font-size: $m8;
              .date {
                font-size: $m7;
              }
              .link {
                font-size: $m7;
              }
            }
          }
        }
        .item {
          width: 100%;
          margin-bottom: $m10;
          padding: 0 $m10;
          height: auto;
          &:before {
            background: #303030;
          }
          .img {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: calc(100% + #{$m20});
            left: -$m10;
            top: 0;
            height: $m150;
            overflow: hidden;
            margin-bottom: $m10;
            z-index: 1;
            img {
              width: 100%;
              height: auto;
            }
          }
          .name {
            height: auto;
            font-size: $m12 !important;
            padding-bottom: $m2 !important;
            padding-left: 0;
            padding-right: 0 !important;
          }
          .text {
            padding: $m5 0 $m10 0 !important;
            font-size: $m10 !important;
            line-height: initial !important;
            color: #c2c2c2;
          }
        }
      }
      .button {
        margin-top: 0;
      }
    }
  }
}
